import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/CapacityBuilding680x680.png'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3>Functional Capacity Building and Rehab</h3>
                            <p>A major part of the work we do is in the area of capacity building to help clients develop the skills and capabilities, and to put the appropriate supports in place that will enable the people to engage in occupations that are important to them. We do this by conducting a functional capacity assessment.</p>

                            <p>A functional capacity assessment is quite broad in scope, it has five main categories: personal care, instrumental activities, community access, work and leisure.</p>
                            
                            <p>We undertake this assessment in your home or via telehealth. We use this assessment as a method of accessing appropriate government support and funding that meets the individual needs of our clients and assists them in reaching their goals.</p>
                            
                            <h3>Functional Capability Assessment Categories</h3>
                            <ul className="technologies-features">
                                <li><span>Personal Care</span></li>
                                <li><span>Instrumental Actvities</span></li>
                                <li><span>Community Access</span></li>
                                <li><span>Work</span></li>
                                <li><span>Leisure</span></li>
                            </ul>
                                                       
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent